//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class ClaimAfterPolicyOverModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	ANO?: number;
	ClaimNumber?: string;
	LossDate?: Date;
	PolicyNumber?: string;
	CertificateNumber?: string;
	Type?: string;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	Information?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
		// this.modelAuditFindingWorkPaper = undefined;
	}

	cleanList(): void
	{

	}

	//#endregion
}

//#endregion