//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class NonStandardCommissionRateModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	ANO?: number;
	PolicyNumber?: string;
	CertificateNumber?: string;
	ProductCode?: string;
	ProductName?: string;
	InsuredObject?: string;
	TotalSumInsured?: number;
	Premium?: number;
	Commission?: number;
	PremiumPaidDate?: Date;
	CommissionPaidDate?: Date;
	CreateUser?: string;
	CreateDate?: Date;
	InforceDate?: Date;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	CommissionRate?: number;
	PolicyCost?: number;
	StampCost?: number;
	Information?: string;
	MaxOutGo?: number;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
	}

	cleanList(): void
	{
	}

	//#endregion
}

//#endregion