//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { AnomalyNotificationModel } from "../models/anomalynotification.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class AnomalyNotificationService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectAnomalyNotificationByToken(interfaceGeneralService: GeneralServiceInterface, modelAnomalyNotification: AnomalyNotificationModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelAnomalyNotification), WebAddressConstant.STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYTOKEN);
	}

	selectAnomalyNotificationByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYATTRIBUTES);
	}

	selectAnomalyNotificationCount(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONCOUNT);
	}

	//#endregion


	//#region DOWNLOAD

	//#endregion

}

//#endregion