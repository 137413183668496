//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ReinsuranceModel } from "../models/reinsurance.model";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { UserModel } from "../models/user.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";


//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ReinsuranceService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectReinsuranceByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_REINSURANCE_SELECTREINSURANCEBYEMAIL);
	}

	//#endregion


	//#region BUSINESS

	verifyReinsuranceByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_REINSURANCE_VERIFYREINSURANCEBYEMAIL);
	}

	signOut(interfaceGeneralService: GeneralServiceInterface, modelReinsurance: ReinsuranceModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelReinsurance), WebAddressConstant.STRING_URL_REINSURANCE_SIGNOUT);
	}

	//#endregionl
}

//#endregion