//#region IMPORT

import { ENUM_ANOMALYGENERAL_STATUS, ENUM_ANOMALY_CATEGORY } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { AnomalyBatchModel } from "./anomalybatch.model";
import { AnomalyEvaluationModel } from "./anomalyevaluation.model";
import { AnomalyFollowUpDocumentModel } from "./anomalyfollowupdocument";
import { BaseModel } from "./bases/base.model";
import { ClaimAfterPolicyOverModel } from "./claimafterpolicyover.model";
import { ClaimBeforePremiumPaidModel } from "./claimbeforepremiumpaid.model";
import { ClaimTemporaryPostModel } from "./claimtemporarypost.model";
import { CommissionBiggerThanPremiumModel } from "./commissionbiggerthanpremium.model";
import { CustomerDoubleCorporateModel } from "./customerdoublecorporate.model";
import { CustomerDoubleIndividualModel } from "./customerdoubleindividual.model";
import { CustomerNotValidModel } from "./customernotvalid.model";
import { MemberDebtModel } from "./memberdept.model";
import { NonStandardCommissionRateModel } from "./nonstandardcommissionrate.model";
import { OutstandingHandlingModel } from "./outstandinghandling.model";
import { PolicyDoubleFireModel } from "./policydoublefire.model";
import { PolicyDoubleMarineCargoModel } from "./policydoublemarinecargo.model";
import { PolicyDoubleMotorVehicleModel } from "./policydoublemotorvehicle.model";
import { PolicyWriteOffModel } from "./policywriteoff.model";
import { PremiumTemporaryPostModel } from "./premiumtemporarypost.model";
import { ReinsuranceFacultativePaymentModel } from "./reinsurancefacultativepayment.model";

//#endregion


//#region CLASS

export class AnomalyGeneralModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyBatchID?: number;
	Token?: string;
	BranchCode?: string;
	BranchName?: number;
	InsuredName?: string;
	ProcessDate?: Date;
	ProcessMonth?: number;
	ProcessYear?: number;
	Category?: ENUM_ANOMALY_CATEGORY;
	Status?: ENUM_ANOMALYGENERAL_STATUS;

	modelCustomerDoubleIndividual?: CustomerDoubleIndividualModel;
	modelCustomerDoubleCorporate?: CustomerDoubleCorporateModel;
	modelCustomerNotValid?: CustomerNotValidModel;
	modelPolicyDoubleFire?: PolicyDoubleFireModel;
	modelPolicyDoubleMarineCargo?: PolicyDoubleMarineCargoModel;
	modelPolicyDoubleMotorVehicle?: PolicyDoubleMotorVehicleModel;
	modelReinsuranceFacultativePayment?: ReinsuranceFacultativePaymentModel;
	modelCommissionBiggerThanPremium?: CommissionBiggerThanPremiumModel;
	modelClaimBeforePremiumPaid?: ClaimBeforePremiumPaidModel;
	modelClaimAfterPolicyOver?: ClaimAfterPolicyOverModel;
	modelMemberDebt?: MemberDebtModel;
	modelClaimTemporaryPost?: ClaimTemporaryPostModel;
	modelPremiumTemporaryPost?: PremiumTemporaryPostModel;
	modelNonStandardCommissionRate?: NonStandardCommissionRateModel;
	modelOutstandingHandling?: OutstandingHandlingModel;
	modelPolicyWriteOff?: PolicyWriteOffModel;

	listModelAnomalyEvaluation?: Array<AnomalyEvaluationModel>;
	listModelAnomalyFollowUpDocument?: Array<AnomalyFollowUpDocumentModel>;
	modelAnomalyBatch?: AnomalyBatchModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelCustomerDoubleCorporate = new CustomerDoubleCorporateModel();
		this.modelCustomerDoubleIndividual = new CustomerDoubleIndividualModel();
		this.modelCustomerNotValid = new CustomerNotValidModel();
		this.modelPolicyDoubleFire = new PolicyDoubleFireModel();
		this.modelPolicyDoubleMarineCargo = new PolicyDoubleMarineCargoModel();
		this.modelPolicyDoubleMotorVehicle = new PolicyDoubleMotorVehicleModel();
		this.modelReinsuranceFacultativePayment = new ReinsuranceFacultativePaymentModel();
		this.modelCommissionBiggerThanPremium = new CommissionBiggerThanPremiumModel();
		this.modelClaimBeforePremiumPaid = new ClaimBeforePremiumPaidModel();
		this.modelClaimAfterPolicyOver = new ClaimAfterPolicyOverModel();
		this.modelMemberDebt = new MemberDebtModel();
		this.modelClaimTemporaryPost = new ClaimTemporaryPostModel();
		this.modelPremiumTemporaryPost = new PremiumTemporaryPostModel();
		this.modelNonStandardCommissionRate = new NonStandardCommissionRateModel();
		this.modelOutstandingHandling = new OutstandingHandlingModel();
		this.modelPolicyWriteOff = new PolicyWriteOffModel();

		this.modelAnomalyBatch = new AnomalyBatchModel();

		this.listModelAnomalyEvaluation = [];
		this.listModelAnomalyFollowUpDocument = [];
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	setPolicyNumber(stringPolicyNumber: string | undefined, stringCertificateNumber: string | undefined): string
	{
		if(stringPolicyNumber == null || stringPolicyNumber === undefined || stringPolicyNumber === StringConstant.STRING_CHARACTER_EMPTY || stringPolicyNumber === StringConstant.STRING_VALIDATION_STRING_EMPTY)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			if(stringCertificateNumber == null || stringCertificateNumber === undefined || stringCertificateNumber === StringConstant.STRING_CHARACTER_EMPTY || stringCertificateNumber === StringConstant.STRING_VALIDATION_STRING_EMPTY)
			{
				return stringPolicyNumber;
			}
			else
			{
				return stringPolicyNumber + " - "+ stringCertificateNumber;
			}
		}
	}

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
		this.modelAnomalyEvaluation = undefined;
		this.modelCustomerDoubleCorporate = undefined;
		this.modelCustomerDoubleIndividual = undefined;
		this.modelPolicyDoubleFire = undefined;
		this.modelPolicyDoubleMarineCargo = undefined;
		this.modelPolicyDoubleMotorVehicle = undefined;
		this.modelReinsuranceFacultativePayment = undefined;
		this.modelCommissionBiggerThanPremium = undefined;
		this.modelClaimBeforePremiumPaid = undefined;
		this.modelClaimAfterPolicyOver = undefined;
		this.modelCustomerNotValid = undefined;
		this.modelMemberDebt = undefined;
		this.modelClaimTemporaryPost = undefined;
		this.modelPremiumTemporaryPost = undefined;
		this.modelNonStandardCommissionRate = undefined;
		this.modelOutstandingHandling = undefined;
		this.modelPolicyWriteOff = undefined;
	}

	cleanList(): void
	{
		this.listModelAnomalyEvaluation = undefined;
		this.listModelAnomalyFollowUpDocument = undefined;
	}

	//#endregion
}

//#endregion