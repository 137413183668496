//#region CLASS

import { BaseModel } from "./bases/base.model";

export class EnumGeneralModel extends BaseModel
{
	ID?: any;
	Name?: string;
}

//#endregion