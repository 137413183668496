//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class ClaimTemporaryPostModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	Voucher?: string;
	VoucherDate?: Date;
	AccountNumber?: string;
	Remarks?: string;
	Total?: number;
	Currency?: string;
	TotalOriginalCurrency?: number;
	Rate?: number;
	ClaimPosemRealization?: number;
	ClaimPosemRealizationOriginalCurrency?: number;
	RealizationEndDate?: Date;
	RealizationTotalDays?: number;
	ClaimPosemRemain?: number;
	ClaimPosemRemainOriginalCurrency?: number;
	CreateUser?: string;
	CreateDate?: Date;
	Information?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
	}

	cleanList(): void
	{
	}

	//#endregion
}

//#endregion