//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class PremiumTemporaryPostModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	Voucher?: string;
	VoucherDate?: Date;
	AccountNumber?: string;
	Remarks?: string;
	Total?: number;
	Currency?: string;
	TotalOriginalCurrency?: number;
	Rate?: number;
	PosemRealization?: number;
	PosemRealizationOriginalCurrency?: number;
	RealizationEndDate?: Date;
	RealizationTotalDays?: number;
	PosemRemain?: number;
	PosemRemainOriginalCurrency?: number;
	Aging?: number;
	Inforamtion?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
	}

	cleanList(): void
	{
	}

	//#endregion
}

//#endregion