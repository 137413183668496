//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class OutstandingHandlingModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	AsAt?: Date;
	PolicyNumber?: string;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	RelationshipManager?: string;
	Voucher?: string;
	Outstanding?: number;
	OutstandingDue?: number;
	OutstandingUndue?: number;
	DueDate?: Date;
	DebtAge?: number;
	Category?: string;
	FirstReminderOutstanding?: number;
	FirstReminderDebtAge?: number;
	SecondReminderOutstanding?: number;
	SecondReminderDebtAge?: number;
	ThirdReminderOutstanding?: number;
	ThirdReminderDebtAge?: number;
	UncoverOutstanding?: number;
	UncoverDebtAge?: number;
	TotalSumInsured?: number;
	Premium?: number;
	PaidDate?: Date;
	PremiumPaid?: number;
	LastOutstanding?: number;
	Information?: string;
	ProductCode?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
	}

	cleanList(): void
	{
	}

	//#endregion
}

//#endregion