//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { DownloadComponent } from "./components/download/download.component";
import { HomeComponent } from "./components/home/home.component";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { SigninComponent } from "./components/signin/signin.component";
import { StringConstant } from "./constants/string.constant";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { PublicGuardService } from "./services/guards/public.guard.service";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadProductAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadProductAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	// {
	// 	path: "home",
	// 	redirectTo: "home/dashboard",
	// },
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "about",
				component: AboutComponent,
			},
			{
				path: "dashboard",
				loadChildren: () => import("./components/dashboards/dashboard.module").then(pageModule => pageModule.DashboardModule)
			},
			{
				path: "ticket",
				loadChildren: () => import("./components/tickets/tickets.module").then(pageModule => pageModule.TicketsModule)
			},
			{
				path: "",
				loadChildren: () => import("./components/approvals/approvals.module").then(pageModule => pageModule.ApprovalsModule)
			},
			{
				path: "certificate",
				loadChildren: () => import("./components/certificate/certificate.module").then(pageModule => pageModule.CertificateModule)
			},
			{
				path: "ematerai",
				loadChildren: () => import("./components/ematerai/ematerai.module").then(pageModule => pageModule.EmateraiModule)
			},
			{
				path: "reimbursement",
				children:
					[
						{
							path: "approval",
							loadChildren: () => import("./components/approvalreimbursement/approval.module").then(pageModule => pageModule.ApprovalModule)
						},
					]
			},
			{
				path: "rcsa",
				children:
				[
					{
						path: "assessment",
						loadChildren: () => import("./components/rcsa/assessment/assessment.module").then(pageModule => pageModule.AssessmentModule)
					},
					{
						path: "approval",
						loadChildren: () => import("./components/rcsa/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
					{
						path: "report",
						loadChildren: () => import("./components/rcsa/report/report.module").then(pageModule => pageModule.ReportModule)
					},
					{
						path: "riskissuerequest",
						loadChildren: () => import("./components/rcsa/riskissuerequest/riskissuerequest.module").then(pageModule => pageModule.RiskissuerequestModule)
					},
				],
			},
			{
				path: "ras",
				children:
				[
					{
						path: "dashboard",
						loadChildren: () => import("./components/ras/dashboardras/dashboardras.module").then(pageModule => pageModule.DashboardrasModule)
					},
					{
						path: "followupplan",
						loadChildren: () => import("./components/ras/followupplan/followupplan.module").then(pageModule => pageModule.FollowupplanModule)
					},
				],
			},
			{
				path: "gcgonline",
				loadChildren: () => import("./components/gcgonline/gcgonline.module").then(pageModule => pageModule.GcgOnlineModule)
			},
			{
				path: "digitalization",
				loadChildren: () => import("./components/digitalization/submissions/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "",
				loadChildren: () => import("./components/policyrenewal/policyrenewal.module").then(pageModule => pageModule.PolicyrenewalModule)
			},
			{
				path: StringConstant.STRING_ROUTING_MENU_PROTECT,
				loadChildren: () => import("./components/protect/protect.module").then(pageModule => pageModule.ProtectModule)
			},
			{
				path: "",
				loadChildren: () => import("./components/cargo/cargo.module").then(pageModule => pageModule.CargoModule)
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_APUPPT,
				loadChildren: () => import("./components/apu-ppt/apu-ppt.module").then(pageModule => pageModule.APUPPTModule)
			},
		]
	},
	{
		path: StringConstant.STRING_ROUTING_MENU_DOWNLOADANOMALYATTACHMENT_PARAM + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AnomalyDownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy
				})
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
