//#region IMPORT

import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CKEditorModule } from "ng2-ckeditor";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxEchartsModule } from "ngx-echarts";
import { NgxEditorModule } from "ngx-editor";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { ApprovalpolicydetailComponent } from "./components/approvalpolicy/approvalpolicydetail/approvalpolicydetail.component";
import { ApprovalpolicylistComponent } from "./components/approvalpolicy/approvalpolicylist/approvalpolicylist.component";
import { ApprovalReimbursementListComponent } from "./components/approvalreimbursement/approvalreimbursementlist/approvalreimbursementlist.component";
import { DentalreimbursementdetailComponent } from "./components/approvalreimbursement/dentalreimbursementdetail/dentalreimbursementdetail.component";
import { MaternityreimbursementdetailComponent } from "./components/approvalreimbursement/maternityreimbursementdetail/maternityreimbursementdetail.component";
import { OpticreimbursementdetailComponent } from "./components/approvalreimbursement/opticreimbursementdetail/opticreimbursementdetail.component";
import { PostmaternityreimbursementdetailComponent } from "./components/approvalreimbursement/postmaternityreimbursementdetail/postmaternityreimbursementdetail.component";
import { PrematernityreimbursementdetailComponent } from "./components/approvalreimbursement/prematernityreimbursementdetail/prematernityreimbursementdetail.component";
import { ApprovaldetailComponent } from "./components/approvals/approvaldetail/approvaldetail.component";
import { ApprovallistComponent } from "./components/approvals/approvallist/approvallist.component";
import { ApproveComponent } from "./components/approvals/approve/approve.component";
import { ConfirmationComponent } from "./components/approvals/confirmation/confirmation.component";
import { ExtraComponent } from "./components/approvals/extras/extra.component";
import { ExtrageneralComponent } from "./components/approvals/extras/extrageneral/extrageneral.component";
import { ExtraindonesianmotorvehicleinsurancestandardpolicyComponent } from "./components/approvals/extras/extraindonesianmotorvehicleinsurancestandardpolicy/extraindonesianmotorvehicleinsurancestandardpolicy.component";
import { ExtraindonesianmotorvehicleinsurancestandardpolicysyariahComponent } from "./components/approvals/extras/extraindonesianmotorvehicleinsurancestandardpolicysyariah/extraindonesianmotorvehicleinsurancestandardpolicysyariah.component";
import { ExtramotorcycleComponent } from "./components/approvals/extras/extramotorcycle/extramotorcycle.component";
import { ExtramotorcyclesyariahComponent } from "./components/approvals/extras/extramotorcyclesyariah/extramotorcyclesyariah.component";
import { ExtramotorvehiclecreditComponent } from "./components/approvals/extras/extramotorvehiclecredit/extramotorvehiclecredit.component";
import { ExtramotorvehiclecreditsyariahComponent } from "./components/approvals/extras/extramotorvehiclecreditsyariah/extramotorvehiclecreditsyariah.component";
import { ExtraotoComponent } from "./components/approvals/extras/extraoto/extraoto.component";
import { ExtraotomaximumprotectionComponent } from "./components/approvals/extras/extraotomaximumprotection/extraotomaximumprotection.component";
import { InsuredComponent } from "./components/approvals/insured/insured.component";
import { InsuredgeneralComponent } from "./components/approvals/insured/insuredgeneral/insuredgeneral.component";
import { InsuredindonesianmotorvehicleinsurancestandardpolicyComponent } from "./components/approvals/insured/insuredindonesianmotorvehicleinsurancestandardpolicy/insuredindonesianmotorvehicleinsurancestandardpolicy.component";
import { InsuredindonesianmotorvehicleinsurancestandardpolicysyariahComponent } from "./components/approvals/insured/insuredindonesianmotorvehicleinsurancestandardpolicysyariah/insuredindonesianmotorvehicleinsurancestandardpolicysyariah.component";
import { InsuredmotorcycleComponent } from "./components/approvals/insured/insuredmotorcycle/insuredmotorcycle.component";
import { InsuredmotorcyclesyariahComponent } from "./components/approvals/insured/insuredmotorcyclesyariah/insuredmotorcyclesyariah.component";
import { InsuredmotorvehiclecreditComponent } from "./components/approvals/insured/insuredmotorvehiclecredit/insuredmotorvehiclecredit.component";
import { InsuredmotorvehiclecreditsyariahComponent } from "./components/approvals/insured/insuredmotorvehiclecreditsyariah/insuredmotorvehiclecreditsyariah.component";
import { InsuredotoComponent } from "./components/approvals/insured/insuredoto/insuredoto.component";
import { InsuredotomaximumprotectionComponent } from "./components/approvals/insured/insuredotomaximumprotection/insuredotomaximumprotection.component";
import { PolicyholderComponent } from "./components/approvals/policyholder/policyholder.component";
import { ProductComponent } from "./components/approvals/products/product.component";
import { ProductgeneralComponent } from "./components/approvals/products/productgeneral/productgeneral.component";
import { ProductindonesianmotorvehicleinsurancestandardpolicyComponent } from "./components/approvals/products/productindonesianmotorvehicleinsurancestandardpolicy/productindonesianmotorvehicleinsurancestandardpolicy.component";
import { ProductindonesianmotorvehicleinsurancestandardpolicysyariahComponent } from "./components/approvals/products/productindonesianmotorvehicleinsurancestandardpolicysyariah/productindonesianmotorvehicleinsurancestandardpolicysyariah.component";
import { ProductmotorcycleComponent } from "./components/approvals/products/productmotorcycle/productmotorcycle.component";
import { ProductmotorcyclesyariahComponent } from "./components/approvals/products/productmotorcyclesyariah/productmotorcyclesyariah.component";
import { ProductmotorvehiclecreditComponent } from "./components/approvals/products/productmotorvehiclecredit/productmotorvehiclecredit.component";
import { ProductmotorvehiclecreditsyariahComponent } from "./components/approvals/products/productmotorvehiclecreditsyariah/productmotorvehiclecreditsyariah.component";
import { ProductotoComponent } from "./components/approvals/products/productoto/productoto.component";
import { ProductotomaximumprotectionComponent } from "./components/approvals/products/productotomaximumprotection/productotomaximumprotection.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { MonitoringcargodetailComponent } from "./components/cargo/monitoringcargodetail/monitoringcargodetail.component";
import { MonitoringcargolistComponent } from "./components/cargo/monitoringcargolist/monitoringcargolist.component";
import { CertificateDetailComponent } from "./components/certificate/certificatedetail/certificatedetail.component";
import { CertificateListComponent } from "./components/certificate/certificatelist/certificatelist.component";
import { DashboardComponent } from "./components/dashboards/dashboard/dashboard.component";
import { DashboardfireComponent } from "./components/dashboards/dashboardfire/dashboardfire.component";
import { DashboardmarinecargoComponent } from "./components/dashboards/dashboardmarinecargo/dashboardmarinecargo.component";
import { SubmissionDetailComponent } from "./components/digitalization/submissions/submissiondetail/submissiondetail.component";
import { SubmissionInsertComponent } from "./components/digitalization/submissions/submissioninsert/submissioninsert.component";
import { SubmissionListComponent } from "./components/digitalization/submissions/submissionlist/submissionlist.component";
import { DownloadComponent } from "./components/download/download.component";
import { EmateraicheckersignerdetailComponent } from "./components/ematerai/emateraicheckersignerdetail/emateraicheckersignerdetail.component";
import { EmateraicheckersignerlistComponent } from "./components/ematerai/emateraicheckersignerlist/emateraicheckersignerlist.component";
import { EmateraimakerdetailComponent } from "./components/ematerai/emateraimakerdetail/emateraimakerdetail.component";
import { EmateraimakerlistComponent } from "./components/ematerai/emateraimakerlist/emateraimakerlist.component";
import { AnnualdisclosurecheckersignerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerdetail/annualdisclosurecheckersignerdetail.component";
import { AnnualdisclosurecheckersignerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerlist/annualdisclosurecheckersignerlist.component";
import { AnnualdisclosuredetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuredetail/annualdisclosuredetail.component";
import { AnnualdisclosureinsertComponent } from "./components/gcgonline/annualdisclosure/annualdisclosureinsert/annualdisclosureinsert.component";
import { AnnualdisclosurelistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurelist/annualdisclosurelist.component";
import { CodeofethicscheckersignerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerdetail/codeofethicscheckersignerdetail.component";
import { CodeofethicscheckersignerlistComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerlist/codeofethicscheckersignerlist.component";
import { CodeofethicsdetailComponent } from "./components/gcgonline/codeofethics/codeofethicsdetail/codeofethicsdetail.component";
import { CodeofethicsinsertComponent } from "./components/gcgonline/codeofethics/codeofethicsinsert/codeofethicsinsert.component";
import { CodeofethicslistComponent } from "./components/gcgonline/codeofethics/codeofethicslist/codeofethicslist.component";
import { GratificationdetailComponent } from "./components/gcgonline/gratification/gratificationdetail/gratificationdetail.component";
import { GratificationlistComponent } from "./components/gcgonline/gratification/gratificationlist/gratificationlist.component";
import { IntegritypactdetailComponent } from "./components/gcgonline/integritypact/integritypactdetail/integritypactdetail.component";
import { IntegritypactinsertComponent } from "./components/gcgonline/integritypact/integritypactinsert/integritypactinsert.component";
import { IntegritypactlistComponent } from "./components/gcgonline/integritypact/integritypactlist/integritypactlist.component";
import { ProfilegcgonlineComponent } from "./components/gcgonline/profilegcgonline/profilegcgonline.component";
import { HomeComponent } from "./components/home/home.component";
import { BarchartIncludeComponent } from "./components/includes/barchart.include/barchart.include.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { RenewalApprovaldetailComponent } from "./components/policyrenewal/approvaldetail/approvaldetail.component";
import { RenewalApprovallistComponent } from "./components/policyrenewal/approvallist/approvallist.component";
import { MonitoringdetailComponent } from "./components/policyrenewal/monitoringdetail/monitoringdetail.component";
import { MonitoringlistComponent } from "./components/policyrenewal/monitoringlist/monitoringlist.component";
import { AnomalyDetailComponent } from "./components/protect/anomaly/anomalydetail/anomalydetail.component";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { AnomalyInsertComponent } from "./components/protect/anomaly/anomalyinsert/anomalyinsert.component";
import { AnomalyListComponent } from "./components/protect/anomaly/anomalylist/anomalylist.component";
import { AnomalyNotificationComponent } from "./components/protect/anomalynotification/anomalynotification.component";
import { DashboardrasdetailComponent } from "./components/ras/dashboardras/dashboardrasdetail/dashboardrasdetail.component";
import { DashboardraslistComponent } from "./components/ras/dashboardras/dashboardraslist/dashboardraslist.component";
import { FollowupplandetailComponent } from "./components/ras/followupplan/followupplandetail/followupplandetail.component";
import { FollowupplanlistComponent } from "./components/ras/followupplan/followupplanlist/followupplanlist.component";
import { ApprovalrcsadetailComponent } from "./components/rcsa/approval/approvalrcsadetail/approvalrcsadetail.component";
import { ApprovalrcsalistComponent } from "./components/rcsa/approval/approvalrcsalist/approvalrcsalist.component";
import { AssessmentdetailComponent } from "./components/rcsa/assessment/assessmentdetail/assessmentdetail.component";
import { AssessmentlistComponent } from "./components/rcsa/assessment/assessmentlist/assessmentlist.component";
import { ReportdetailComponent } from "./components/rcsa/report/reportdetail/reportdetail.component";
import { ReportrcsalistComponent } from "./components/rcsa/report/reportrcsalist/reportrcsalist.component";
import { RiskissuerequestdetailComponent } from "./components/rcsa/riskissuerequest/riskissuerequestdetail/riskissuerequestdetail.component";
import { RiskissuerequestlistComponent } from "./components/rcsa/riskissuerequest/riskissuerequestlist/riskissuerequestlist.component";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { TicketdetailComponent } from "./components/tickets/ticketdetail/ticketdetail.component";
import { TicketlistinboxComponent } from "./components/tickets/ticketlistinbox/ticketlistinbox.component";
import { TicketlistoutboxComponent } from "./components/tickets/ticketlistoutbox/ticketlistoutbox.component";
import { TicketReportComponent } from "./components/tickets/ticketreport/ticketreport/ticketreport.component";
import { TicketReportSatisfactionComponent } from "./components/tickets/ticketreport/ticketreportsatisfaction/ticketreportsatisfaction.component";
import { TicketSatisfactionComponent } from "./components/tickets/ticketsatisfaction/ticketsatisfaction.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { PwaService } from "./services/pwa.service";
import { CustomerdataComponent } from "./components/apu-ppt/customerdata/customerdata.component";
import { RiskbasedassessmentComponent } from "./components/apu-ppt/riskbasedassessment/riskbasedassessment.component";
import { TransactionlistComponent } from "./components/apu-ppt/transaction/transactionlist/transactionlist.component";
import { TransactiondetailComponent } from "./components/apu-ppt/transaction/transactiondetail/transactiondetail.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();

const maskConfig: Partial<IConfig> = {
	validation: false,
};
@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			DashboardComponent,
			TableControlIncludeComponent,
			TicketdetailComponent,
			ApprovallistComponent,
			ApprovalpolicydetailComponent,
			ApprovalpolicylistComponent,
			ApprovaldetailComponent,
			ProductComponent,
			PolicyholderComponent,
			InsuredComponent,
			ExtraComponent,
			ApproveComponent,
			ConfirmationComponent,
			TicketlistinboxComponent,
			TicketlistoutboxComponent,
			TicketSatisfactionComponent,
			TicketReportComponent,
			TicketReportSatisfactionComponent,
			CertificateListComponent,
			CertificateDetailComponent,
			DownloadComponent,
			ProductotoComponent,
			ProductgeneralComponent,
			ProductindonesianmotorvehicleinsurancestandardpolicyComponent,
			ProductmotorcycleComponent,
			ProductmotorvehiclecreditComponent,
			InsuredgeneralComponent,
			InsuredmotorvehiclecreditComponent,
			InsuredindonesianmotorvehicleinsurancestandardpolicyComponent,
			InsuredotoComponent,
			InsuredmotorcycleComponent,
			ProductindonesianmotorvehicleinsurancestandardpolicysyariahComponent,
			ExtrageneralComponent,
			ExtraotoComponent,
			ExtraindonesianmotorvehicleinsurancestandardpolicyComponent,
			ExtramotorcycleComponent,
			ExtramotorvehiclecreditComponent,
			InsuredindonesianmotorvehicleinsurancestandardpolicysyariahComponent,
			ExtraindonesianmotorvehicleinsurancestandardpolicysyariahComponent,
			ProductotomaximumprotectionComponent,
			ProductmotorcyclesyariahComponent,
			ProductmotorvehiclecreditsyariahComponent,
			InsuredotomaximumprotectionComponent,
			InsuredmotorcyclesyariahComponent,
			InsuredmotorvehiclecreditsyariahComponent,
			ExtraotomaximumprotectionComponent,
			ExtramotorcyclesyariahComponent,
			ExtramotorvehiclecreditsyariahComponent,
			ApprovalReimbursementListComponent,
			DentalreimbursementdetailComponent,
			OpticreimbursementdetailComponent,
			PostmaternityreimbursementdetailComponent,
			PrematernityreimbursementdetailComponent,
			MaternityreimbursementdetailComponent,
			AssessmentlistComponent,
			AssessmentdetailComponent,
			ApprovalrcsalistComponent,
			ApprovalrcsadetailComponent,
			ReportdetailComponent,
			ReportrcsalistComponent,
			RiskissuerequestlistComponent,
			RiskissuerequestdetailComponent,
			SubmissionInsertComponent,
			SubmissionListComponent,
			SubmissionDetailComponent,
			RenewalApprovallistComponent,
			RenewalApprovaldetailComponent,
			MonitoringdetailComponent,
			MonitoringlistComponent,
			AnomalyListComponent,
			AnomalyInsertComponent,
			AnomalyDetailComponent,
			AnomalyDownloadComponent,
			AnomalyNotificationComponent,
			MonitoringcargolistComponent,
			MonitoringcargodetailComponent,
			DashboardmarinecargoComponent,
			CheckDevToolsDirective,
			DashboardraslistComponent,
			DashboardrasdetailComponent,
			FollowupplanlistComponent,
			FollowupplandetailComponent,
			EmateraicheckersignerlistComponent,
			EmateraicheckersignerdetailComponent,
			EmateraimakerlistComponent,
			EmateraimakerdetailComponent,
			DashboardfireComponent,
			BarchartIncludeComponent,
			ProfilegcgonlineComponent,
			GratificationlistComponent,
			GratificationdetailComponent,
			IntegritypactlistComponent,
			IntegritypactinsertComponent,
			IntegritypactdetailComponent,
			CodeofethicslistComponent,
			CodeofethicsinsertComponent,
			CodeofethicsdetailComponent,
			CodeofethicscheckersignerlistComponent,
			CodeofethicscheckersignerdetailComponent,
			AnnualdisclosurelistComponent,
			AnnualdisclosureinsertComponent,
			AnnualdisclosuredetailComponent,
			AnnualdisclosurecheckersignerlistComponent,
			AnnualdisclosurecheckersignerdetailComponent,
			CustomerdataComponent,
			RiskbasedassessmentComponent,
			TransactionlistComponent,
			TransactiondetailComponent
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			ReactiveFormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			NgxChartsModule,
			MatNativeDateModule,
			MatInputModule,
			MatDatepickerModule,
			NgSelectModule,
			NgxEditorModule,
			NgxMaskDirective,
			NgxMaskPipe,
			CKEditorModule,
			PdfViewerModule,
			DragDropModule,
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: environment.serviceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			}),
			NgxEchartsModule.forRoot
			(
				{
					echarts: () => import("echarts")
				}
			),
		],
		providers:
		[
			provideEnvironmentNgxMask(maskConfig),
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			}
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion