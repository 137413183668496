//#region IMPORT

import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class PolicyWriteOffModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	WriteOff?: string;
	Outstanding?: number;
	PercentageOutstanding?: number;
	IsAnomaly?: boolean;
	TotalPolicy?: number;

	NoteWriteOff?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	checkAnomaly(): string
	{
		if(this.IsAnomaly)
		{
			return StringConstant.STRING_FIELD_POLICYWRITEOFF_ISANOMALY;
		}
		else
		{
			return StringConstant.STRING_FIELD_POLICYWRITEOFF_NOTANOMALY;
		}
	}

	setWriteOff(): void
	{
		if(this.WriteOff === "0 - 60")
		{
			this.NoteWriteOff = "penentuan cadangan kerugian penurunan nilai piutang premi Lancar jumlah nya 0 sampai 60 hari Persentase 5%-20%";
		}
		else if(this.WriteOff === "61 - 90")
		{
			this.NoteWriteOff = "penentuan cadangan kerugian penurunan nilai piutang premi Dalam Perhatian Khusus jumlah nya 61 sampai 90 hari Persentase >20%-30%";
		}
		else if(this.WriteOff === "91 - 180")
		{
			this.NoteWriteOff = "penentuan cadangan kerugian penurunan nilai piutang premi Kurang Lancar jumlah nya 91 sampai 180 hari Persentase >30%-40%";
		}
		else if(this.WriteOff === "181 - 365")
		{
			this.NoteWriteOff = "penentuan cadangan kerugian penurunan nilai piutang premi Diragukan jumlah nya 181 sampai 365 hari Persentase >40%-75%";
		}
		else if(this.WriteOff === "> 365")
		{
			this.NoteWriteOff = "penentuan cadangan kerugian penurunan nilai piutang premi Macet jumlah nya >365 hari Persentase 100%";
		}
		else
		{
			this.NoteWriteOff = StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
	}

	cleanList(): void
	{
	}

	//#endregion
}

//#endregion