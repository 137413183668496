//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class PolicyDoubleMotorVehicleModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	ANO?: number;
	PolicyNumber?: string;
	CertificateNumber?: string;
	PolicyDate?: Date;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	ProductCode?: string;
	LicensePlate?: string;
	InsuredObject?: string;
	ChasisNumber?: string;
	MachineNumber?: string;
	TotalSumInsured?: number;
	Premium?: number;
	CanceledStatus?: string;
	ProcessDate?: Date;
	PremiumPaidDate?: Date;
	Outstanding?: number;
	ReinsurancePaidDate?: Date;
	DoubleStatus?: string;
	ReinsurancePremium?: number;
	ReinsuranceOutstandingPremium?: number;
	Voucher?: string;
	PremiumPaid?: number;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
		// this.modelAuditFindingWorkPaper = undefined;
	}

	cleanList(): void
	{
		this.AuditFindingWorkPaperCriteriaTableModel = undefined;
	}

	//#endregion
}

//#endregion